import React, {useEffect} from 'react';

const GoogleTagManager = () => {
    useEffect(() => {
        let source = ''
        let e;
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            source = j.src;
            e = j;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-M2BRH7W9');

        return ()=>{
            // remove the script
            if (e.remove) {
                e.remove();
                e = null;
            }
        }
    },[]);
    return (
        <noscript>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-M2BRH7W9"
                height="0"
                width="0"
                style="display: none;visibility:hidden"
            ></iframe>
        </noscript>
    );
};

export default GoogleTagManager;
