import {useNavigate} from 'react-router-dom';
import {useAuth} from './useAuth';
import {useIdle} from 'react-use';
import {ROOTS_AUTH} from 'routes/path';
import {useEffect} from 'react';

export const useAutoLogout = (options = {}) => {
    const navigate = useNavigate();
    const {logout} = useAuth();
    const isIdle = useIdle(60e3 * 60);
    useEffect(() => {
        if (isIdle) {
            logout();
            console.log('logout by auto logout')
            navigate(ROOTS_AUTH);
        }
        return () => {};
    }, [isIdle]);
};
