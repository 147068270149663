import {
    FormControl,
    FormLabel,
    Button,
    HStack
} from '@chakra-ui/react';
import Selector from 'components/Selector';
import CustomDialog from './CustomDialog';
import {useState,useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import useCustomToast from 'hooks/useCustomToast';
import {get, isNil} from 'lodash';
import {StyledButton} from 'components/StyledBtn';
import {useUserForexAccountList, editMtRebateAccount} from 'hooks/datahook/forex';
import { getErrorMessage } from 'utils/error';
import {useAuth} from '../../hooks/useAuth';

export default ({open, onClose, user, nodeId,value,mutate}) => {
    const {isUser} = useAuth();
    const [loading, setLoading] = useState(false);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const {data: accounts} = useUserForexAccountList({
        user: user,
        type: 'Rebate',
        source: 'MT4'
    });
    const {t} = useTranslation('app');
    const toast = useCustomToast();

    useEffect(() => {
        setDefaultAccount(value);
    }, [value]);

    useEffect(() => {
        if (isNil(defaultAccount)) {
            setDefaultAccount(value);
        }
    }, [defaultAccount]);

    const reset = () => {
        setDefaultAccount(value);
    };


    const onSubmit = async () => {
        try {
            setLoading(true);
            await editMtRebateAccount({
                nodeId: Number(nodeId),
                accountId: defaultAccount,
                user: !isUser ? user : null,
            });
            toast.show({
                title: t('updateSuccess'),
                status: 'success',
            });
            mutate && (await mutate());
            onClose();
            setLoading(false);
        } catch (error) {
            // console.log(error)
            setLoading(false);
            toast.show({
                title: t('updateFailed'),
                description: getErrorMessage(error),
                status: 'error',
            });
        }
    };
    return (
        <CustomDialog
            open={open}
            onClose={()=>{
                onClose();
                reset();
            }}
            title={t('editMt4RebateAccount')}
            actions={
                <HStack>
                    <Button size={'sm'} onClick={() => onClose()}>{t('cancel')}</Button>
                    <StyledButton
                        isLoading={loading}
                        onClick={() => onSubmit()}
                    >
                        {t('submit')}
                    </StyledButton>
                </HStack>
            }
        >
            <FormControl>
                <FormLabel>{t('mt4RebateAccount')}</FormLabel>
                <Selector
                    options={accounts.map((item) => {
                        return {
                            label: `${get(item, 'accountSource')}-${get(
                                item,
                                'login',
                            )}`,
                            value: get(item, 'id'),
                        };
                    })}
                    value={defaultAccount}
                    onChangeValue={(newValue) => setDefaultAccount(newValue)}
                />
            </FormControl>
        </CustomDialog>
    );
};
