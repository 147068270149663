import React, {useEffect} from 'react';
import {Outlet, useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Center, HStack, Stack, useColorMode} from '@chakra-ui/react';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import {useColorModeValueKey} from 'hooks/useColors';
import Page from 'components/Page';
import {usePlatformBasicInfo} from 'hooks/datahook/auth';
import {useInIframe} from 'hooks/useInIFrame';
import referralBg from 'assets/images/guestBg.jpg';
import Logo from 'assets/images/OXLogo3.jpg';
import { useSourceRecorder } from 'hooks/useSourceRecorder';

const GuestLayout = (props) => {
    const {center=true} = props
    const {t, i18n} = useTranslation('app');
    const {data: basicInfo, isLoading} = usePlatformBasicInfo();
    const {colorMode, setColorMode} = useColorMode();
    // console.log(colorMode);
    const inIframe = useInIframe();
    const [searchParams] = useSearchParams();
    const defaultColorMode = searchParams.get('colorMode');
    const defaultLang = searchParams.get('lang');
    useSourceRecorder()
    useEffect(() => {
        if (
            defaultColorMode &&
            defaultColorMode !== colorMode &&
            ['light', 'dark'].includes(defaultColorMode)
        ) {
            setColorMode(defaultColorMode);
        }
    }, [defaultColorMode]);

    useEffect(() => {
        if (
            defaultLang &&
            i18n.language !== defaultLang &&
            ['en-US', 'zh-CN', 'zh-TW', 'jp-JP', 'ko-KR'].includes(defaultLang)
        ) {
            i18n.changeLanguage(defaultLang);
        }
    }, [defaultLang]);
    return (
        <Page title={isLoading ? t('loading') : basicInfo.companyShortName}>
            <Box
                position={'relative'}
                width={'100vw'}
                bg={
                    colorMode === 'dark'
                        ? '#101010'
                        : useColorModeValueKey('bg')
                }
                transition="background-color 200ms linear"
                // bg={useColorModeValueKey('bg')}
                sx={
                    colorMode === 'dark'
                        ? {
                              backgroundImage: `url(${referralBg})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                          }
                        : {}
                }
            >
                {inIframe ? null : (
                    <HStack
                        position={'absolute'}
                        alignItems={'flex-end'}
                        justifyContent={'flex-end'}
                        right={8}
                        top={4}
                        spacing={2}
                        w={'100vw'}
                    >
                        <LanguageBtn />
                        <ColorModeBtn />
                    </HStack>
                )}
                <Stack
                    mx="auto"
                    alignItems={'center'}
                    justifyContent={center ? 'center' : 'flex-start'}
                    // position={'absolute'}
                    width={'100vw'}
                    minHeight={'100vh'}
                    // overflow={'scroll'}
                    // bg={useColorModeValueKey('bg')}
                >
                    {inIframe ? (
                        <Outlet />
                    ) : (
                        <Stack
                            mt={center?- 8:8}
                            mb={3}
                            spacing={8}
                            align={'center'}
                        >
                            <img
                                src={Logo}
                                style={{
                                    height: '120px',
                                    maxWidth: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                            <Outlet />
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Page>
    );
};

export default GuestLayout;
