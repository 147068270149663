export const paymentWaysIdChannelMap = {
    1: 'worldpay',
    2: 'finmo',
    3: 'bitwallet',
    4: 'awepay',
    5: 'fasapay',
    6: 'eft',
    7: 'web3',
    8: 'usdt',
    9: 'customerSupport',
    10: 'dragonpay',
    11: 'nacepay',
};

export const paymentWaysNameChannelMap = {
    Worldpay: 'worldpay',
    Finmo: 'finmo',
    Bitwallet: 'bitwallet',
    Awepay: 'awepay',
    Fasapay: 'fasapay',
    'Bank Transfer': 'eft',
    'Web3.0': 'web3',
    USDT: 'usdt',
    'Customer Support': 'customerSupport',
    Dragonpay: 'dragonpay',
    'NACE Pay': 'nacepay',
    'Korean Solution': 'koreanSolution',
    'Sub Account': 'ccSubAccount',
    'ExLink': 'exLink',
};

export const paymentIcons = {
    worldpay: require('assets/images/worldPay.jpg'),
    worldpayPng: require('assets/images/Worldpay.png'),
    eft: require('assets/images/BankTransfer.png'),
    usdt: require('assets/images/USDT.png'),
    rapyd: require('assets/images/rapyd.jpg'),
    customerSupport: require('assets/images/CustomerSupport.png'),
    bitwallet: require('assets/images/bitwallet.jpg'),
    awepay: require('assets/images/awepay.jpg'),
    fasapay: require('assets/images/fasapay.jpg'),
    web3: require('assets/images/USDT.png'),
    finmo: require('assets/images/paynowTrans.png'),
    dragonpay: require('assets/images/dragonpay.png'),
    nacepay: require('assets/images/nace.jpeg'),
    nacepayWidget: require('assets/images/nace.jpeg'),
    ccSubAccount: require('assets/images/BankTransfer.png'),
    koreanSolution: require('assets/images/Dynamic Virtual Account 2.png'),
    exLink: require('assets/images/exLink.png'),
};

// from currency limit
export const paymentGatewayLimitCurrency = {
    bitwallet: ['USD', 'JPY'],
    awepay: ['IDR', 'MYR', 'THB', 'VND'],
    fasapay: ['IDR'],
    finmo: ['SGD'],
    dragonpay: ['PHP'],
    koreanSolution: ['KRW'],
    worldpay: ['USD', 'AUD', 'SGD', 'GBP', 'EUR', 'CAD'],
    exLink: ['CNY'],
};

export const paymentGatewayLimitAmount = {
    dragonpay: {
        PHP: 500,
    },
    koreanSolution: {
        KRW: 130000,
    },
    exLink: {
        CNY: 3000,
    }
};
export const paymentGatewayMaxLimitAmount = {
    worldpay: {
        AUD: 30000,
        CAD: 20000,
        EUR: 15000,
        GBP: 10000,
        SGD: 20000,
        USD: 15000,
    },
};

// to currency limit
export const paymentGatewayLimitToCurrency = {
    // usdt: ['USD'],
    web3: ['USD'],
    nacepay: ['USD', 'CAD', 'EUR', 'AUD', 'GBP'],
    exLink: ['USD', 'AUD', 'EUR'],
};

// to stock limit
export const paymentGatewayLimitToStock = {
    worldpay: ['stock'],
};


export const nacepayCryptos = [
    'BTC',
    'BCH',
    'ETH',
    'LTC',
    'DOGE',
    'XRP',
    'ERC20USDC',
    'SOL',
    'BNB',
    'DASH',
    'ADA',
    'AVAX',
    'Polygon',
    'ERC20SHIB',
    "ERC20USDT",
    "TRC20USDT"
];

export const processPaymentGateway = (rawData) => {
    return {
        rawData,
    };
};

export const cryptoIcons = {
    USDT: require('assets/images/USDT.png'),
};

export const underMaintenance = [
    // 'nacepayWidget',
];
export const comingSoon = [];
export const gatewayDisabled = [
    'bitwallet',
    'customerSupport',
    'web3',
    'nacepay',
    'exLink',
    'usdt' /*'dragonpay', 'koreanSolution' */,
];

export const paymentGatewayNotAllowedRegion = {
    worldpay: ['US','PH'],
};

export const ccSubAccountAllowedCurrencies = ['USD', 'EUR', 'GBP', 'AUD', 'CAD'];
