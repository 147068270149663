import {Center, Spinner} from '@chakra-ui/react';
import {getLoginReferral_} from 'hooks/datahook/auth';
import {use} from 'i18next';
import { debounce } from 'lodash';
import React, {useEffect} from 'react';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {authPaths, paths} from 'routes/path';

const OXOldSystemIBRedirect = () => {
    const [searchParams] = useSearchParams();
    const ib = searchParams.get('ib');
    const navigate = useNavigate();
    const getIBReferralCode_ = debounce(async (ib) => {
        const {referralCode} = await getLoginReferral_(ib);
        if (referralCode) {
            navigate(`${authPaths.signUp}?code=${referralCode}`);
        } else {
            navigate(`/404`);
        }
    },400);
    useEffect(() => {
        if (ib) {
            getIBReferralCode_(ib);
        }
    }, [ib]);
    if (!ib) {
        return <Navigate to={'/404'} />;
    }
    return (
        <Center h="100vh">
            <Spinner />
        </Center>
    );
};

export default OXOldSystemIBRedirect;
