import {create} from 'zustand';

export const useDepositStore = create((set, get) => ({
    amount: '',
    setAmount: (value) => set(() => ({amount: value})),
    fromAmount: '',
    setFromAmount: (value) => set(() => ({fromAmount: value})),
    currency: '',
    setCurrency: (value) => set(() => ({currency: value})),
    fromCurrency: '',
    setFromCurrency: (value) => set(() => ({fromCurrency: value})),
    fromCrypto: null,
    setFromCrypto: (value) => set(() => ({fromCrypto: value})),
    depositResult: null,
    setDepositResult: (value) => set(() => ({depositResult: value})),
    channel: '',
    setChannel: (value) => set(() => ({channel: value})),
    channelId: null,
    setChannelId: (value) => set(() => ({channelId: value})),
    account: '',
    setAccount: (value) => set(() => ({account: value})),
    accountDetail: null,
    setAccountDetail: (value) => set(() => ({accountDetail: value})),
    transactionId: null,
    setTransactionId: (value) => set(() => ({transactionId: value})),
    uniqueCode: null,
    setUniqueCode: (value) => set(() => ({uniqueCode: value})),
    networkId: '',
    setNetworkId: (value) => set(() => ({networkId: value})),
    network: null,
    setNetwork: (value) => set(() => ({network: value})),
    step: 0,
    setStep: (value) => set(() => ({step: value})),


    reset: () =>
        set(() => ({
            amount: '',
            fromAmount: '',
            currency: '',
            channel: '',
            channelId: null,
            fromCurrency: null,
            fromCrypto: null,
            account: '',
            accountDetail: null,
            transactionId: null,
            uniqueCode: null,
            step: 0,
            networkId: '',
            network: '',
        })),
}));
