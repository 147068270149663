import React, {useCallback, useMemo} from 'react';
import {Box, Tag, TagLabel, useColorMode, useToken} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {designSystemColors} from 'theme';
import { useColorModeValueKey } from 'hooks/useColors';

const StatusTag = (props) => {
    const {status} = props;
    const {t, i18n} = useTranslation('app');
    const colorMode = 'light';

    const getSchema = useCallback(
        (status) => {
            switch (status) {
                case 'actived':
                case 'configed':
                case 'online':
                case 'activated':
                case 'completed':
                case 'processed':
                case 'passed':
                case 'approved':
                case 'replyed':
                case 'verified':
                case 'success':
                case 'enable':
                case 'attempted':
                case 'contacted':
                case 'interested':
                case 'valid':
                case 'true':
                    return `${colorMode}.success`;

                case 'pending':
                case 'renewPending':
                case 'processing':
                case 'inactive':
                case 'unReplyed':
                case 'unregistered':
                case 'new':
                    return `${colorMode}.pending`;

                case 'error':
                case 'failed':
                case 'invalid':
                case 'declined':
                    return `${colorMode}.error`;

                case 'renew':
                case 'draft':
                case 'return':
                    return `${colorMode}.warning`;

                case 'offline':
                case 'unconfiged':
                case 'suspended':
                case 'disable':
                case 'converted':
                case 'false':
                    return 'gray';

                case 'noInterested':
                    return `${colorMode}.lightGray`;
            }
        },
        [colorMode],
    );

    const item = useMemo(
        () => (
            <Tag size={'sm'} variant="solid" colorScheme={getSchema(status)}>
                <TagLabel>{t(status)}</TagLabel>
            </Tag>
        ),
        [colorMode, status, i18n.language],
    );
    return item;
};

export default StatusTag;
