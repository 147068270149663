// import CountryIcon from 'components/common/CountryIcon';
import {
    Box,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import {find} from 'lodash';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CountryIcon from './CountryIcon';

const LanguageBtn = (props) => {
    const {noShadow} = props;
    const {t, i18n} = useTranslation('app');

    const languageList = [
        {
            label: t('englishConsist'),
            value: 'en-US',
            county: 'US',
            shortLabel: 'EN',
        },
        {
            label: t('chineseConsist'),
            value: 'zh-CN',
            county: 'CN',
            shortLabel: '简中',
        },
        {
            label: t('chineseTWConsist'),
            value: 'zh-TW',
            county: 'CN',
            shortLabel: '繁中',
        },
        // {
        //     label: t('japaneseConsist'),
        //     value: 'jp-JP',
        //     county: 'JP',
        //     shortLabel: '日本語',
        // },
        {
            label: t('koreanConsist'),
            value: 'ko-KR',
            county: 'kr',
            shortLabel: '한국',
        },
    ];
    const matched = find(languageList, (o) => o.value === i18n.language);
    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                // icon={<CountryIcon country={matched.county} size={24} />}
                boxShadow={'none'}
                variant={'ghost'}
                px={2}
                fontSize={'sm'}
            >
                {matched.shortLabel}
            </MenuButton>
            <MenuList>
                {languageList.map((item) => (
                    <MenuItem
                        onClick={() => i18n.changeLanguage(item.value)}
                        key={item.value}
                        // icon={<CountryIcon country={item.county} size={24} />}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default LanguageBtn;
