import React from 'react';
import Brochure, {printDocument} from 'components/Brochure';
import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'react-use';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash';
import useCustomToast from 'hooks/useCustomToast';
import CustomDialog from './CustomDialog';
import {Button, HStack} from '@chakra-ui/react';
import {usePlatformBasicInfo} from 'hooks/datahook/auth';

const BrochureDialog = (props) => {
    const {open, onClose, profile} = props;
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const [state, copyToClipboard] = useCopyToClipboard();
    const toast = useCustomToast();
    const {data: platformBasicInfo} = usePlatformBasicInfo();
    const link = `${
        isUser ? window.location.origin : `https://portal.oxsecurities.com`
    }/auth/sign-up?code=${get(profile, 'referralReference')}`;

    const onCopyLink = () => {
        copyToClipboard(link);
        toast.show({title: t('copied')});
    };

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title={platformBasicInfo?.companyShortName}
            actions={
                <HStack spacing={2}>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => printDocument({id: 'brochure'})}
                    >
                        {t('download')}
                    </Button>
                    <Button variant="primary" size="sm" onClick={onCopyLink}>
                        {t('copyLink')}
                    </Button>
                </HStack>
            }
        >
            <Brochure profile={profile} platformBasicInfo={platformBasicInfo} />
        </CustomDialog>
    );
};

export default BrochureDialog;
