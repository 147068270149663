import {useColorModeValueKey} from 'hooks/useColors';
import {get, isNil, reduce} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'react-use';
import useCustomToast from 'hooks/useCustomToast';
import {getErrorMessage} from 'utils/error';
import {useUserPreference} from 'hooks/datahook/settings';
import BtnGroup from 'components/BtnGroup';
import {useProfile} from 'hooks/datahook/auth';
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Link,
    PinInput,
    PinInputField,
    Stack,
    Text,
} from '@chakra-ui/react';
import {sendVerifyCode_} from 'hooks/datahook/common';
import CustomDialog from '../CustomDialog';

const TwoFactorConfirmDialog = (props) => {
    const {
        open,
        onClose,
        customReview,
        customReviewTitle,
        onFinalSumit,
        needTwoFact = false,
        limitMethod,
        nextDisabled = false,
        customNext = null,
    } = props;
    const {
        data: settings,
        twoFactorEnabled: userEneableTwoFact,
        isLoading: isLoadingSetting,
    } = useUserPreference();
    const twoFactorEnabled = needTwoFact && userEneableTwoFact;
    const {data: profile} = useProfile();
    const [step, setStep] = useState(1);
    const {t} = useTranslation('app');
    const [sessionExpired, setSessionExpired] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [code, setCode] = useState('');
    const [resendCounter, setResendCounter] = useState(0);
    const verifyRef = useRef(null);
    const toast = useCustomToast();
    const [twoFactorMethod, setTwoFactorMethod] = useState(() =>
        get(settings, 'enableOtpFact')
            ? 'otp'
            : get(settings, 'enableEmailFact')
            ? 'email'
            : get(settings, 'enableSmsFact')
            ? 'sms'
            : null,
    );
    useEffect(() => {
        if (!open) {
            setStep(1);
            // setSessionExpired(false);
            setCode('');
            setTwoFactorMethod(
                get(settings, 'enableOtpFact')
                    ? 'otp'
                    : get(settings, 'enableEmailFact')
                    ? 'email'
                    : get(settings, 'enableSmsFact')
                    ? 'sms'
                    : null,
            );
        }
    }, [open, settings]);
    // useInterval(
    //     () => {
    //         if (countDown - 1 == 0) {
    //             setSessionExpired(true);
    //         }
    //         setCountDown((c) => c - 1);
    //     },
    //     countDown === 0 ? null : 1000,
    // );

    useInterval(
        () => {
            setResendCounter((c) => c - 1);
        },
        resendCounter === 0 ? null : 1000,
    );
    const inCoolDown = resendCounter > 0;

    const onSendVerify = async (twoFactorMethod) => {
        try {
            setIsSending(true);
            const {issueId} = await sendVerifyCode_({method: twoFactorMethod});
            verifyRef.current = issueId;
            setResendCounter(120);
        } catch (error) {
            toast.show({
                title: t('sendFailed'),
                status: 'error',
                description: getErrorMessage(error),
            });
            console.log(error);
        } finally {
            setIsSending(false);
        }
    };

    const twoFactorMethodBtns = [
        {
            label: t('otp'),
            value: 'otp',
            enabled: get(settings, 'enableOtpFact'),
        },
        {
            label: t('email'),
            value: 'email',
            enabled: get(settings, 'enableEmailFact'),
        },
        {
            label: t('sms'),
            value: 'sms',
            enabled: get(settings, 'enableSmsFact'),
        },
    ].filter((item) =>
        limitMethod
            ? (typeof limitMethod === 'string'
                  ? [limitMethod]
                  : limitMethod
              ).includes(item.value)
            : true,
    );

    const needPick =
        reduce(
            twoFactorMethodBtns,
            (acc, item) => acc + (item.enabled ? 1 : 0),
            0,
        ) > 1;

    const twoFactor = (
        <Stack spacing={2}>
            {needPick && (
                <BtnGroup
                    btns={twoFactorMethodBtns}
                    selected={twoFactorMethod}
                    onSelect={(v) => {
                        setTwoFactorMethod(v);
                        onSendVerify(v);
                    }}
                />
            )}
            {twoFactorMethod === 'email' && (
                <Stack spacing={3}>
                    <Stack spacing={0}>
                        <Text textAlign={'center'} fontSize={'sm'}>
                            {t('verificaitonCodeWillBeSentToYourEmail')}
                        </Text>
                        <Text textAlign={'center'} fontSize={'md'}>
                            {get(profile, 'email')}
                        </Text>
                    </Stack>
                    <FormControl>
                        <Center>
                            <HStack>
                                <PinInput
                                    value={code}
                                    onChange={setCode}
                                    variant={'outline'}
                                >
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                        </Center>
                    </FormControl>
                    <HStack justifyContent={'center'}>
                        {inCoolDown ? (
                            <Text
                                fontSize={'sm'}
                                color={useColorModeValueKey('gray')}
                            >
                                {t('youCanResendIn', {seconds: resendCounter})}
                            </Text>
                        ) : (
                            <Text fontSize={'sm'}>
                                <Link
                                    color={useColorModeValueKey('primary')}
                                    onClick={() => onSendVerify('email')}
                                    isDisabled={isSending}
                                >
                                    {t('Click here')}
                                </Link>{' '}
                                {t('to resend code')}
                            </Text>
                        )}
                    </HStack>
                </Stack>
            )}

            {twoFactorMethod === 'sms' && (
                <Stack spacing={3}>
                    <Stack spacing={3}>
                        <Stack spacing={0}>
                            <Text textAlign={'center'} fontSize={'sm'}>
                                {t('verificaitonCodeWillBeSentToYourMobile')}
                            </Text>
                            <Text textAlign={'center'} fontSize={'md'}>
                                {get(profile, 'mobile')}
                            </Text>
                        </Stack>
                        <FormControl>
                            <Center>
                                <HStack>
                                    <PinInput
                                        value={code}
                                        onChange={setCode}
                                        variant={'outline'}
                                    >
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                            </Center>
                        </FormControl>
                        <HStack justifyContent={'center'}>
                            {inCoolDown ? (
                                <Text
                                    fontSize={'sm'}
                                    color={useColorModeValueKey('gray')}
                                >
                                    {t('youCanResendIn', {
                                        seconds: resendCounter,
                                    })}
                                </Text>
                            ) : (
                                <Text fontSize={'sm'}>
                                    <Link
                                        color={useColorModeValueKey('primary')}
                                        onClick={onSendVerify}
                                        isDisabled={isSending}
                                    >
                                        {t('Click here')}
                                    </Link>{' '}
                                    {t('to resend code')}
                                </Text>
                            )}
                        </HStack>
                    </Stack>
                </Stack>
            )}
            {twoFactorMethod === 'otp' && (
                <Stack spacing={3}>
                    <Stack spacing={3}>
                        <Stack spacing={0}>
                            <Text textAlign={'center'} fontSize={'sm'}>
                                {t(
                                    'inputVerificationCodeInYourAuthenticatorApp',
                                )}
                            </Text>
                        </Stack>
                        <FormControl>
                            <Center>
                                <HStack>
                                    <PinInput
                                        value={code}
                                        onChange={setCode}
                                        variant={'outline'}
                                    >
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                        <PinInputField />
                                    </PinInput>
                                </HStack>
                            </Center>
                        </FormControl>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    const onConfirmBtn_ = async () => {
        try {
            setSubmiting(true);
            if (twoFactorEnabled) {
                if (step === 1) {
                    setStep(2);
                    onSendVerify(twoFactorMethod);
                } else if (step === 2) {
                    await onFinalSumit({
                        method: twoFactorMethod,
                        code,
                        issueId: verifyRef?.current,
                    });
                    onClose();
                }
            } else {
                await onFinalSumit({});
            }
        } catch (error) {
            throw error;
        } finally {
            setSubmiting(false);
        }
    };

    const confirmText = sessionExpired
        ? t('close')
        : !twoFactorEnabled
        ? t('confirm')
        : step === 1
        ? t('next')
        : t('confirm');

    const disabled =
        (twoFactorEnabled &&
            step === 2 &&
            (code.length != 6 || isNil(twoFactorMethod))) ||
        nextDisabled;

    const steps = [
        {
            title: customReviewTitle || t('summary'),
            component: customReview,
        },
        ...(twoFactorEnabled
            ? [
                  {
                      title: t('twoFactor'),
                      component: twoFactor,
                  },
              ]
            : []),
    ];

    const matchedStep = steps[step - 1];
    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            title={
                sessionExpired && !submiting
                    ? t('transactionExpired')
                    : matchedStep.title
            }
        >
            <Stack bg="whiteColor" rounded={'md'} width={'100%'} space={3}>
                <Stack px={0} py={1}>
                    {sessionExpired && !submiting ? (
                        <Stack>
                            <Text>{t('transactionExpiredDesc')}</Text>
                        </Stack>
                    ) : (
                        matchedStep.component
                    )}
                </Stack>
                {/* <Divider /> */}
                <HStack
                    pb={3}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                >
                    {/* {expiredIn && !sessionExpired ? (
                        <Text fontSize={'sm'}>
                            {t('transactionExpiredIn', {second: countDown})}
                        </Text>
                    ) : (
                        <Box />
                    )} */}
                    <HStack alignItems={'center'} spacing={2}>
                        {!sessionExpired && (
                            <Button
                                size={'sm'}
                                variant="ghost"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {t('cancel')}
                            </Button>
                        )}
                        <Button
                            size={'sm'}
                            isLoading={submiting}
                            onClick={sessionExpired ? onClose : customNext && step === 1 ?()=>customNext(onConfirmBtn_): onConfirmBtn_}
                            isDisabled={disabled}
                            variant={'primary'}
                        >
                            {confirmText}
                        </Button>
                    </HStack>
                </HStack>
            </Stack>
        </CustomDialog>
    );
};

export default TwoFactorConfirmDialog;
