import {
    addHours,
    format,
    formatDistance,
    formatRelative,
    isValid,
    parseISO,
} from 'date-fns';
import {enUS, zhCN, zhTW} from 'date-fns/locale';
import {
    get,
    isNil,
    take,
    takeRight,
    toUpper,
    split,
    trim,
    toLower,
} from 'lodash';
import numeral from 'numeral';
import validator from 'validator';
import PasswordValidator from 'password-validator';
import {
    utcToZonedTime,
    getTimezoneOffset,
    format as tzFormat,
} from 'date-fns-tz';
import getSymbolFromCurrency from 'currency-symbol-map';
import {verifyIfItIsInteger, verifyIfItIsLetter} from './validator';

export const currencyFormator = (value, currency = '') => {
    return currencyFormatorWithDecimal(value, currency);
};

export const currencyFormaterOfficialWithSymbol = (value, currencyCode) => {
    if (toUpper(currencyCode) === 'JPY') {
        return currencyFormatorWithDecimal(
            value,
            getSymbolFromCurrency(
                currencyCode === 'AUD.L' ? 'AUD' : currencyCode,
            ),
            0,
            true,
        );
    } else {
        return currencyFormatorWithDecimal(
            value,
            getSymbolFromCurrency(
                currencyCode === 'AUD.L' ? 'AUD' : currencyCode,
            ),
            2,
            true,
        );
    }
};

export const currencyFormatorWithDecimal = (
    value,
    currency = '',
    decimal = 2,
    fixedDecimal = false,
) => {
    if (!isNil(value)) {
        const isNagtive = Number(value) < 0;
        if (fixedDecimal) {
            return (
                `${isNagtive ? '-' : ''}${currency ? `${currency} ` : ''}` +
                numeral(Math.abs(Number(value))).format(
                    `0,0.${Array(decimal).fill('0').join('')}`,
                    Math.floor,
                )
            );
        } else {
            return (
                `${isNagtive ? '-' : ''}${currency ? `${currency} ` : ''}` +
                numeral(Math.abs(Number(value))).format(
                    `0,0.[${Array(decimal).fill('0').join('')}]`,
                    Math.floor,
                )
            );
        }
    } else {
        return '';
    }
};

export const currencyFormatorDetail = (value, currency, withCode = false) => {
    if (toUpper(currency) === 'JPY') {
        return currencyFormatorWithDecimal(
            value,
            withCode ? currency : '',
            2,
            true,
        );
    } else {
        return currencyFormatorWithDecimal(
            value,
            withCode ? currency : '',
            4,
            true,
        );
    }
};

export const currencyFormatorOfficial = (value, currency, withCode = false) => {
    if (toUpper(currency) === 'JPY') {
        return currencyFormatorWithDecimal(
            value,
            withCode ? currency : '',
            0,
            true,
        );
    } else {
        return currencyFormatorWithDecimal(
            value,
            withCode ? currency : '',
            2,
            true,
        );
    }
};

export const USDTFormator = (value) => {
    return numeral(value).format('0,0.[000000]', Math.floor);
};

export const percentageFormator = (value) => {
    return numeral(value).format('0.[00]%', Math.floor);
};

export const KFormator = (value) => {
    return numeral(value).format('0.[0]a', Math.floor);
};

export const RateFormator = (value) => {
    return value != 1 && value ? numeral(value).format('0,0.00000') : 1;
};

export const NumberFormator = (value) => {
    return value && numeral(value).format('0,0');
};

export const formatMonthYear = (date) => {
    if (date instanceof Date) {
        return format(date, 'MMMM yyyy');
    }
    return format(parseISO(date), 'MMMM yyyy');
};

export const formatDateTime = (date) => {
    if(date){
        if (date instanceof Date) {
            return format(date, 'dd/MM/yyyy HH:mm:ss');
        }
        return format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');
    }else{
        return ''
    }
};

export const formatDateTimeWithTimezone = (date) => {
    if (date instanceof Date) {
        return format(date, 'dd/MM/yyyy HH:mm:ss OOOO');
    } else {
        return format(parseISO(date), 'dd/MM/yyyy HH:mm:ss OOOO');
    }
};

export const formatDateTimes = (date) => {
    if (date instanceof Date) {
        return format(date, 'dd/MM/yyyy HH:mm:ss');
    } else {
        if (date) {
            return format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');
        } else {
            return '';
        }
    }
};
export const formatDateTimes2 = (date) => {
    if (date instanceof Date) {
        return format(date, 'yyyy-MM-dd HH:mm:ss');
    } else {
        if (date) {
            return format(parseISO(date), 'dd/MM/yyyy HH:mm:ss');
        } else {
            return '';
        }
    }
};

export const formatDate = (date, short = false) => {
    if (date instanceof Date) {
        return format(date, short ? 'dd/MM/yy' : 'dd/MM/yyyy');
    } else {
        if (isValid(parseISO(date))) {
            return format(parseISO(date), short ? 'dd/MM/yy' : 'dd/MM/yyyy');
        }
    }
};
export const formatDayMonthYear = (ISODate) => {
    if (!ISODate) return;
    return format(parseISO(ISODate), 'dd/MM/yyyy');
};

export const formatDateMonth = (date) => {
    if (date instanceof Date) {
        return format(date, 'MM/yy');
    } else {
        return format(parseISO(date), 'MM/yy');
    }
};
export const formatDateTimeHomeHeader = (date) => {
    if (date instanceof Date) {
        return format(date, 'HH:mm - dd/MM/yyyy');
    } else {
        return format(parseISO(date), 'HH:mm - dd/MM/yyyy');
    }
};

export const formatMonth = (date) => {
    if (date instanceof Date) {
        return format(date, 'yyyy-MM');
    } else {
        return format(parseISO(date), 'yyyy-MM');
    }
};

export const formatMonthDataAndYear = (date) => {
    if(date){
        if (date instanceof Date) {
            return format(date, 'yyyy-MM-dd');
        } else {
            return format(parseISO(date), 'yyyy-MM-dd');
        }
    }else{
        return ''
    }
};

export const formatDateMonthYear = (date) => {
    if (date instanceof Date) {
        return format(date, 'MM/yyyy');
    } else {
        return format(parseISO(date), 'MM/yyyy');
    }
};

export const formatRelativeDay = (ISODate, locale = 'en') => {
    return formatRelative(parseISO(ISODate), new Date(), {locale});
};

export const i18nLocaleMapper = {
    'en-US': enUS,
    'zh-CN': zhCN,
    'zh-TW': zhTW,
};

export const formateTimeLeft = (ISODate, locale = 'en-US') => {
    return formatDistance(parseISO(ISODate), new Date(), {
        locale: i18nLocaleMapper[locale] || enUS,
    });
};

export const walletAddressShrinkFormator = (v) => {
    return (v?.length || 0) > 10
        ? `${take(v, 4).join('')}...${takeRight(v, 4).join('')}`
        : v;
};

export const emailMask = (email) => {
    return email.replace(
        /^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, '*') + c,
    );
};

export const vaildPhoneNumber = (value) =>
    value ? validator.isMobilePhone(value, 'any', {strictMode: true}) : true;

export const passwordSchema = new PasswordValidator()
    .is()
    .min(6, 'min')
    .is()
    .max(20, 'max')
    .has()
    .uppercase(1, 'upper')
    .has()
    .lowercase(1, 'lower')
    .has()
    .digits(1, 'digital')
    .has()
    .symbols(1, 'symbol')
    .is()
    .not()
    .oneOf(['test1234']);

export const formatorChart = (
    value,
    currency,
    decimal = 4,
    withCode = false,
) => {
    return currencyFormatorWithDecimal(value, '', decimal, true);
};

export const getLastDayOfMonth = (date) => {
    const arr = date.split('-');
    const maxDay = new Date(arr[0], arr[1], 0).getDate();
    return maxDay;
};

export const getLastWeekOfMonth = (date) => {
    const week = date.getDay();
    const endTime = new Date(date.getTime() + (7 - week) * 24 * 60 * 60 * 1000);
    return endTime.toLocaleDateString();
};

export const getLastOfMonth = (date) => {
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return end;
};

export const getLocalTime = (
    date,
    timeZone = 'Australia/Sydney',
    formator = 'dd/MM/yyyy',
) => {
    const targetTimeZoneOffset = getTimezoneOffset(timeZone);
    const localTimeZoneOffset = getTimezoneOffset(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const diffHours =
        (targetTimeZoneOffset - localTimeZoneOffset) / (1000 * 60 * 60);

    const dateTime = utcToZonedTime(date, timeZone);
    return format(new Date(dateTime.setHours(0 - diffHours)), formator);
};

export const convertTZ = (date, timeZone) => {
    return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString(
            'en-US',
            {timeZone: timeZone},
        ),
    );
};

export const formatDateInTimezone = (
    date,
    timeZone = 'Factory',
    format = 'dd/MM/yyyy',
) => {
    const d = typeof date === 'string' ? new Date(date) : date;
    const zonedDate = utcToZonedTime(d, timeZone);
    const output = tzFormat(zonedDate, format, {timeZone});
    return output;
};

export const formatDateTimezone = (
    date,
    timeZone = 'Factory',
    format = 'dd/MM/yyyy HH:mm:ss',
) => {
    const zonedDate = utcToZonedTime(date, timeZone);
    const output = tzFormat(zonedDate, format, {timeZone});
    return output;
};

// export const converLocalTimeToAustraliaSydneyTime = (date) => {
//     const targetTimeZoneOffset = getTimezoneOffset('Australia/Sydney');
//     const localTimeZoneOffset = getTimezoneOffset(
//         Intl.DateTimeFormat().resolvedOptions().timeZone,
//     );
//     const diffHours =
//         (targetTimeZoneOffset - localTimeZoneOffset) / (1000 * 60 * 60);
//     const dateTime = utcToZonedTime(date, 'Australia/Sydney');

// }

export const forexPasswordSchema = new PasswordValidator()
    .is()
    .min(8, 'min')
    .is()
    .max(20, 'max')
    .has()
    .uppercase(1, 'upper')
    .has()
    .lowercase(1, 'lower')
    .has()
    .digits(1, 'digital')
    .has()
    .symbols(1, 'symbol')
    .is()
    .not()
    .oneOf(['test1234']);

export const formatDecimalPlaces = (num, decimal) => {
    num = num.toString();
    let index = num.indexOf('.');
    if (index !== -1) {
        num = num.substring(0, decimal + index + 1);
    } else {
        num = num.substring(0);
    }
    return parseFloat(num).toFixed(decimal);
};

export const getLastAndLastDayOfMonth = (date) => {
    let startDate = new Date(date).setDate(1);
    let endDate = new Date(date).setMonth(new Date(date).getMonth() + 1);
    endDate = new Date(endDate).setDate(0);
    startDate = formatMonthDataAndYear(new Date(startDate).toISOString());
    endDate = formatMonthDataAndYear(new Date(endDate).toISOString());
    return{
        startDate: startDate,
        endDate: endDate,
    }
};