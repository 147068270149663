import {
    clientCanEditStatus,
    clientFunctionActivedStatus,
    kycInRenewStatus,
    kycRequiredStatus,
    kycReviewingStatus,
} from 'constant/status';
import {getAddressPayload} from 'data/common';
import {getLegalRepresentativePayloadObj} from 'data/leaglRepresentative';
import {processUserProfile} from 'data/userProfile';
import client, {adminClient, authClient} from 'helper/httpClient';
import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {findIndex} from 'lodash';
import {split} from 'lodash';
import {lowerCase} from 'lodash';
import {get} from 'lodash';
import {formatMonthDataAndYear} from 'utils/formator';
import {api} from './common';
import {useExchangeRateStore} from 'stores/exchangeRateStore';
import {useCurrencyRates} from './forex';
import {cleanObjectNilValue} from 'utils/clean';
import {useStackAccount} from './stock';
import {usePlatformProduct} from './settings';

export const forgetPasswordSendEmail_ = async (email) => {
    await authClient.post(['forgot'], {email});
};

export const resetpassword_ = async (data) => {
    await authClient.post(['reset'], data);
};

export const resendCode_ = async ({issueId}) => {
    await authClient.post(['resend-code'], {issueId});
};

export const verifyCode_ = async (data) => {
    await authClient.post(['activate'], data);
};


export const useProfile = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const {data: stockData} = useStackAccount();
    const {stockPermissions} = usePlatformProduct();
    const {data: CurrencyRates} = useCurrencyRates();
    const exchangeRateState = useExchangeRateStore((state) => state);
    const swrResult = useSWRTemplate({
        key: id ? ['profile', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['profile', _id]);
            const res = processUserProfile(result);
            if (get(result, ':preference.preferredPlatformCurrency')) {
                exchangeRateState.setCurrency(get(result, ':preference.preferredPlatformCurrency'));
            } else {
                exchangeRateState.setCurrency('USD');
                exchangeRateState.setExchangeRate(1);
            }
            return res;
        },
        defaultValue: {},
    });
    const steps = get(swrResult, 'data.rawData.:steps');
    const serverDateTime = get(swrResult, 'data.rawData.now');
    const currentStepInex = findIndex(
        steps,
        (o) => Boolean(o.completed) === false,
    );
    const isComplete = currentStepInex === -1;
    const stepNum = steps?.length || 0;
    const remainStepNumber = isComplete
        ? 0
        : currentStepInex !== -1
            ? stepNum - currentStepInex
            : 0;
    const currentStep = stepNum - remainStepNumber;

    const needRenewPassword = Boolean(
        get(swrResult, 'data.rawData.needRenewPassword'),
    );
    const kycVerified = clientFunctionActivedStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycRequired = kycRequiredStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycPending = kycReviewingStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycInRenew = kycInRenewStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );

    return {
        ...swrResult,
        serverDateTime: serverDateTime,
        stepNum,
        remainStepNumber,
        currentStep,
        profile: get(swrResult, 'data'),
        roles: get(swrResult, 'data.rawData.:roles'),
        steps,
        needRenewPassword,
        reference: get(swrResult, 'data.rawData.reference'),
        name: get(swrResult, 'data.rawData.name'),
        isCompany: Boolean(lowerCase(get(swrResult, 'data.type')) == 'company'),
        isIB: get(swrResult, 'data.isIB'),
        isStock: Boolean(get(swrResult, 'data.preference.isStock') && stockPermissions?.length) || false,
        hasStockAccount: Boolean(stockData.length),
        needRenewKyc: get(swrResult, 'data.kycStatus') === 'renew',
        kycVerified,
        kycPending,
        kycRequired,
        kycInRenew,
        updateProfile: async (payload) => {
            await client.put(['profile', id], payload);
            if (payload?.preferredPlatformCurrency) {
                const rate = get(CurrencyRates, `${payload?.preferredPlatformCurrency}USD`) ?
                    1 / get(CurrencyRates, `${payload?.preferredPlatformCurrency}USD`)?.price :
                    get(CurrencyRates, `USD${payload?.preferredPlatformCurrency}`) ?
                        get(CurrencyRates, `USD${payload?.preferredPlatformCurrency}`)?.price : 1;
                exchangeRateState.setCurrency(payload?.preferredPlatformCurrency);
                exchangeRateState.setExchangeRate(rate);
            }
            await swrResult.mutate();
        },
        clientCanEdit: clientCanEditStatus.includes(
            get(swrResult, 'data.kycStatus'),
        ),
        functionActived:
            clientFunctionActivedStatus.includes(
                get(swrResult, 'data.kycStatus'),
            ) && !needRenewPassword,
    };
};

export const useIndividualKycDetail = (options = {}) => {
    const {} = options;
    const {profile, ...other} = useProfile();

    return {
        ...other,
        submitPersonalInformation_: async (person) => {
            const phone = get(person, 'phone', '');
            const phoneCode = split(phone, '@')?.[0] || '';
            const phoneNo = split(phone, '@')?.[1] || '';

            // const transferNeeds = get(estimate, 'transferNeeds', '');
            // const fromCurrency = split(transferNeeds, '/')?.[0] || '';
            // const toCurrency = split(transferNeeds, '/')?.[1] || '';
            const address = getAddressPayload(person, 'individual');
            const payload = {
                person: {
                    title: get(person, 'title', ''),
                    firstname: get(person, 'firstname', ''),
                    middlename: get(person, 'middlename', ''),
                    lastname: get(person, 'lastname', ''),
                    nativeLanguageFirstname: get(person, 'firstNameNative', ''),
                    nativeLanguageMiddlename: get(
                        person,
                        'middleNameNative',
                        '',
                    ),
                    nativeLanguageLastname: get(person, 'lastNameNative', ''),
                    // TODO issue32
                    phoneCode,
                    phoneNo,
                    referrer: get(person, 'referrer', ''),
                    region: get(person, 'region', ''),
                    occupation: get(person, 'occupation', ''),
                    dob: get(person, 'dob', '')
                        ? formatMonthDataAndYear(get(person, 'dob', ''))
                        : null,
                },
                // estimate: {
                //     fromCurrency,
                //     toCurrency,
                //     amount: Number(get(estimate, 'transferAmount', '')),
                // },
                address: address,
            };
            await client.post(['personal', 'kyc', 'PI'], payload);
            await other.mutate();
        },
        submitUploadDocument_: async (value) => {
            const payload = {
                frontPhotoOfPrimary: get(value, 'primaryDocumentFrontSide'),
                backPhotoOfPrimary: get(value, 'primaryDocumentBackSide'),
                photoOfSecondary: get(value, 'secondaryIdentificationDocument'),
                photoOfAdditional:
                    get(value, 'additionalPhotoIdentification') || null,
            };
            await client.post(['personal', 'kyc', 'UD'], payload);
            await other.mutate();
        },
        submitReviewApplication_: async () => {
            await client.post(['personal', 'kyc', 'RA']);
            await other.mutate();
        },
        submitApplication_: async (value) => {
            await client.post(
                ['personal', 'kyc', 'SA'],
                cleanObjectNilValue({
                    accountTerms: true,
                    complaintsProcedures: true,
                    privacyPolicy: true,
                    signature: get(value, 'signature'),
                    fxAccount: get(value, 'fxAccount'),
                    issueId: get(value, 'issueId'),
                    code: get(value, 'code'),
                    method: get(value, 'method'),
                }),
            );
            await other.mutate();
        },
    };
};

export const useCompanyKycDetail = (options = {}) => {
    const {} = options;
    const {profile, ...other} = useProfile();
    return {
        ...other,
        submitBasicInformation_: async (company) => {
            // const transferNeeds = get(estimate, 'transferNeeds', '');
            // const fromCurrency = split(transferNeeds, '/')?.[0] || '';
            // const toCurrency = split(transferNeeds, '/')?.[1] || '';

            const payload = {
                company: {
                    name: get(company, 'name', ''),
                    businessNo: get(company, 'businessNo', ''),
                    industryName: get(company, 'industryName', ''),
                    officialWebsite: get(company, 'businessURL', ''),
                    region: get(company, 'region'),
                },
                // estimate: {
                //     fromCurrency,
                //     toCurrency,
                //     amount: Number(get(estimate, 'transferAmount', '')),
                // },
                address: getAddressPayload(company, 'company'),
                documents: get(company, 'companyDocuments', []),
            };
            await client.post(['company', 'kyb', 'CI'], payload);
            await other.mutate();
        },
        submitLegalRepresentative_: async (values = []) => {
            const payload = {
                representatives: values.map(getLegalRepresentativePayloadObj),
            };
            await client.post(['company', 'kyb', 'LR'], payload);
            await other.mutate();
        },
        submitAuthorisedPerson_: async ({id, newValue}) => {
            const payload = id
                ? {authorisedPersonId: id}
                : {
                    authorisedPerson:
                        getLegalRepresentativePayloadObj(newValue),
                };
            await client.post(['company', 'kyb', 'AP'], payload);
            await other.mutate();
        },
        submitReviewApplication_: async () => {
            await client.post(['company', 'kyb', 'RA']);
            await other.mutate();
        },
        submitApplication_: async (value) => {
            await client.post(
                ['company', 'kyb', 'SA'],
                cleanObjectNilValue({
                    accountTerms: true,
                    complaintsProcedures: true,
                    privacyPolicy: true,
                    signature: get(value, 'signature'),
                    fxAccount: get(value, 'fxAccount'),
                    issueId: get(value, 'issueId'),
                    code: get(value, 'code'),
                    method: get(value, 'method'),
                }),
            );
            await other.mutate();
        },
    };
};

export const useAdminProfile = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['admin/profile', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['user', _id]);
            const res = processUserProfile(result);
            return res;
        },
        defaultValue: {},
    });

    return {
        ...swrResult,
        profile: get(swrResult, 'data'),
        roles: get(swrResult, 'data.rawData.:roles'),
        name: get(swrResult, 'data.rawData.name'),
        updateProfile: async (payload) => {
            await adminClient.put(['profile', id], payload);
            await swrResult.mutate();
        },
    };
};

export const useLoginLog = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['loginLog', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['login-log']);
            return result;
        },
        defaultValue: {},
    });
    return swrResult;
};


export const useIbTerms = (options = {}) => {
    const {} = options;
    const {id, isUser} = useAuth();
    const swrResult = useSWRTemplate({
        key: id && isUser ? ['ibTerms', id] : null,
        request: async ([_, _id]) => {
            const {result} = await client.get(['ib', 'terms']);
            return result?.termsAndCondition;
        },
        defaultValue: {},
    });
    return swrResult;
};

export const usePlatformBasicInfo = (options = {}) => {
    const {} = options;
    const swrResult = useSWRTemplate({
        key: ['platform-basic-info'],
        request: async ([_, _id]) => {
            const {result} = await authClient.get(['basic-info']);
            return result;
        },
        defaultValue: {},
    });
    return swrResult;
};

export const changeIndividualToCompany_ = async (userId) => {
    await adminClient.post(['change-to-company'], {
        userId,
    });
};

export const useLatestKycResult = () => {
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['latest-kyc-result', id] : null,
        request: async ([_, _id]) => {
            const {result} = await client.get(['latest-kyc-result']);
            return result;
        },
        defaultValue: {},
    });
    return swrResult;
};


export const checkOptCode_ = async (data) => {
    const {result} = await api().post(['check-opt-code'], data)
    return result
}


export const getLoginReferral_ = async (ib)=>{
    const {result} = await authClient.get(['login-referral'],{
        ib
    })
    return result
}
