import React from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
} from '@chakra-ui/react';
import {ColorModeSwitcher} from './ColorModeSwitcher';
import {Logo} from './Logo';
import theme from './theme';
import Wizard from './page/Wizard';
import RootRouter from 'routes';
import {FormProvider} from 'components/FormGenerator/ContextProvider';
import {
    formComponents,
    viewComponents,
} from 'components/FormGenerator/FormGeno';
import Page from 'components/Page';
import appConfig from 'config/app';
import {
    ethereumClient,
    projectId,
    tronAdapters,
    wagmiConfig,
} from 'components/Web3/Web3Config';
import {WagmiConfig} from 'wagmi';
import {Web3Modal} from '@web3modal/react';
import {WalletProvider} from '@tronweb3/tronwallet-adapter-react-hooks';

function App() {
    return (
        <ChakraProvider theme={theme}>
                <FormProvider
                    formComponents={formComponents}
                    viewComponents={viewComponents}
                >
                    {/* <WalletProvider
                        onError={(e) => console.log(e)}
                        adapters={tronAdapters}
                        autoConnect={false}
                    > */}
                        {/* <WagmiConfig config={wagmiConfig}> */}
                            <Page ico={appConfig.ico}>
                                <RootRouter />
                            </Page>
                        {/* </WagmiConfig> */}
                    {/* </WalletProvider> */}
                    {/* <Web3Modal
                        projectId={projectId}
                        ethereumClient={ethereumClient}
                        themeVariables={{
                            '--w3m-font-family': `Inter, sans-serif`,
                        }}
                    /> */}
                </FormProvider>
        </ChakraProvider>
    );
}

export default App;
