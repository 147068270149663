import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

const CustomDialog = (props) => {
    const {title,open,onClose,children,actions,maxW='800px',...other} = props
    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            isCentered
            scrollBehavior="outside"
            size={{base: 'sm', md: 'lg'}}
            {...other}
        >
            <ModalOverlay />
            <ModalContent maxW={maxW}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                {/* <ModalBody maxHeight={{ base: '100%', md: '70vh' }} overflow='auto'>{children}</ModalBody> */}
                <ModalBody>{children}</ModalBody>
                {actions && <ModalFooter>{actions}</ModalFooter>}
            </ModalContent>
        </Modal>
    );
};

export default CustomDialog;
