import CustomTable from 'components/CustomTable';
import {Box} from '@chakra-ui/react';
import StyledChip from 'components/StyledChip';
import {StyledTableButton} from 'components/StyledBtn';
import ClientDetailCell from 'components/TableCell/ClientDetailCell';
import StatusTableCell from 'components/TableCell/StatusTableCell';
import {get} from 'lodash';
import {useTranslation} from 'react-i18next';
import {currencyFormatorWithDecimal, formatDateTimes} from 'utils/formator';
import {useState} from 'react';
import RequestDetailDialog from 'components/Dialog/RequestDetailDialog';
import ClosedOrderDetails from 'page/app/Account/ClosedOrderDetails';

import {formatDate} from 'utils/formator';
import numeral from 'numeral';

export default ({
    data,
    totalPage,
    page,
    setPage,
    totalItems,
    pageSize,
    pageIndex,
    isLoading,
    userId,
    setIsDetail = () => {},
    detail,
    setDetail,
}) => {
    const [showDetail, setShowDetail] = useState(false);
    const {t} = useTranslation('app');
    const onRowClick = (rowData) => {
        setIsDetail(true);
        setDetail(rowData);
    };
    const column = [
        {
            label: t('date'),
            dataKey: 'date',
            columProps: {
                align: 'left',
                flexGrow: 1,
                minWidth: 140,
            },
            renderCell: (rawData) => {
                return formatDate(get(rawData, 'date'));
            },
        },
        {
            label: t('source'),
            dataKey: 'origin',
            columProps: {
                align: 'left',
                flexGrow: 1,
                minWidth: 140,
            },
        },
        {
            label: t('currency'),
            dataKey: 'currency',
            columProps: {
                align: 'left',
                minWidth: 140,
                flexGrow: 1,
            },
        },
        {
            label: t('closedLots'),
            dataKey: 'volume',
            columProps: {
                align: 'left',
                minWidth: 140,
                flexGrow: 1,
            },
            renderCell: (rowData) => {
                return numeral(get(rowData, 'volume')).format('0,0.00');
            },
        },
    ];
    return (
        <Box overflow={'auto'} h="100%">
            <CustomTable
                column={column}
                {...{
                    data,
                    totalPage,
                    page,
                    setPage,
                    totalItems,
                    pageSize,
                    loading: isLoading,
                    pageIndex,
                }}
                noCard={true}
                onRowClick={onRowClick}
            />
            <ClosedOrderDetails
                account={detail}
                isOpen={showDetail}
                onClose={() => setShowDetail(false)}
                userId={userId}
            />
        </Box>
    );
};
