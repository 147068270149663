import {processTreeView} from 'data/treeview';
import client, {adminClient} from 'helper/httpClient';
import {useState} from 'react';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {get, orderBy} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {formatMonthYear, currencyFormatorDetail, currencyFormaterOfficialWithSymbol, formatMonthDataAndYear} from 'utils/formator';
import {api} from './common';
import {useAuth} from '../useAuth';
import Excel from 'exceljs';

export const useIbInfo = () => {
    const swr = useSWRTemplate({
        key: ['ib-info'],
        request: async (_) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    ':user': {
                        name: 'Mary Anna',
                    },
                    reference: '1000001818',
                    referralCode: 'HSJKCYS',
                },
            };

            return result;
        },
    });
    return swr;
};

export const useTotalCommission = (options = {}) => {
    const {isUser, id} = useAuth();
    const {date} = options;
    const swr = useSWRTemplate({
        key: isUser && id ? ['total-commission', date, id] : null,
        request: async ([_, _date, _id]) => {
            const {result} = await api().get(
                ['summary', 'commission-user-month'],
                {yearMonth: _date},
            );
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUserTotalCommission = (options = {}) => {
    const {userId, date} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-total-commission', date, userId] : null,
        request: async ([_, _date, _userId]) => {
            const {result} = await adminClient.get(
                ['summary', 'commission-user-month'],
                {
                    yearMonth: _date,
                    user: _userId,
                },
            );
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useSubLevel = () => {
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: isUser && id ? ['sub-level', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['ib', 'invitation-count']);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUpperIb = () => {
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: isUser && id ? ['client/upper-ib', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['ib', 'upper-ib', _id]);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUserSubLevel = (options = {}) => {
    const {userId} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-user-subLevel', userId] : null,
        request: async ([_, _userId]) => {
            const {result} = await adminClient.get([
                'user',
                _userId,
                'invitation-count',
            ]);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useSubIbDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['sub-ib-detail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _id,
                }),
            );
            return result;
        },
    });
    return swr;
};

export const useTreeView = (data) => {
    const swr = useSWRTemplate({
        key: data?.id ? ['tree-view'] : null,
        request: async (_) => {
            const {result} = await api().get(['ib', 'tree']);
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminTreeView = (nodeId, renderKey) => {
    const [index, setIndex] = useState(0);
    const swr = useSWRTemplate({
        key: nodeId ? ['tree-view', nodeId, index, renderKey] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await adminClient.get(
                ['ib', 'tree'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return {
        ...swr,
        mutate: async () => {
            setIndex(index + 1);
        },
    };
};

export const useAdminUserTreeView = (data) => {
    const swr = useSWRTemplate({
        key: data?.id ? ['admin-user-tree-view'] : null,
        request: async (_) => {
            const {result} = await api().get(['user', data.id, 'ib-tree']);
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return swr;
};

export const useRebateAccountList = (options = {}) => {
    const {isUser, id} = useAuth();
    const {date} = options;
    const swr = useSWRTemplate({
        key: isUser && id ? ['rebate-account-list', date, id] : null,
        request: async ([_, _date, _id]) => {
            const {result} = await api().get(['summary', 'commission-month'], {
                yearMonth: _date,
            });
            const arr = orderBy(
                result,
                (o) => Number(get(o, 'total') || 0),
                'desc',
            );

            return [
                ...arr.filter((d) => d?.isDefault),
                ...arr.filter((d) => !d?.isDefault),
            ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useUserRebateAccountList = (options = {}) => {
    const {userId, date} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-rebate-account-list', date, userId] : null,
        request: async ([_, _date, _userId]) => {
            const {result} = await adminClient.get(
                ['summary', 'commission-month'],
                {
                    yearMonth: _date,
                    user: _userId,
                },
            );
            const arr = orderBy(
                result,
                (o) => Number(get(o, 'total') || 0),
                'desc',
            );

            return [
                ...arr.filter((d) => d?.isDefault),
                ...arr.filter((d) => !d?.isDefault),
            ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useClientFxAccounts = () => {
    const swr = useSWRPaginationTemplate({
        key: ['client-forex-accounts'],
        request: async (_, _pageIndex, _pageSize) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    items: new Array(10).fill(0).map((item, index) => {
                        return {
                            accountType: 'MT4 STD',
                            login: '20001100' + index + 1,
                            currency: 'USD',
                            closedOrders: 84612,
                            commissions: 18612,
                        };
                    }),
                    totalItems: 10,
                },
            };
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
    });
    return swr;
};

export const useUserClosedOrders = ({accountId}) => {
    const swr = useSWRPaginationTemplate({
        key: accountId ? ['client-forex-accounts', accountId] : null,
        request: async (_, _pageIndex, _pageSize) => {
            const [_key, _accountId] = _;
            const query = {
                accountId: _accountId,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['fx', 'close-order'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
    });
    return swr;
};

export const useSubPendingClients = () => {
    const swr = useSWRPaginationTemplate({
        key: ['sub-pending-clients'],
        request: async (_, _pageIndex, _pageSize) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    items: new Array(6).fill(0).map((item, index) => {
                        return {
                            ':user': {
                                id: 10045,
                                name: 'test ib' + index + 1,
                                email: 'testib' + index + 1 + '@gmail.com',
                            },
                            profitRate: 0.3,
                            agentId: 100001918,
                            status: 'pending',
                        };
                    }),
                    totalItems: 6,
                },
            };
            return {
                result,
            };
        },
    });
    return swr;
};

export const useSelectMonth = () => {
    const swr = useSWRTemplate({
        key: ['select-month'],
        request: async (_) => {
            // const { result } = await adminClient.get(['ib','info'])
            const result = [
                {
                    label: formatMonthYear(new Date().toISOString()),
                    value: new Date().toISOString(),
                },
            ];
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const setDefaultRebate_ = async (id, data) => {
    const res = await api().put(['fx', 'account', id], data);
    return res;
};

export const applyAsIb_ = async (acceptTerms,currency) => {
    const res = await client.post(['ib', 'apply'], {
        acceptTerms: acceptTerms,
        currency: currency,
    });
    return res;
};

const checkLength = (str) => {
    if(str){
        if(str.length >= 3) return str
        if(str.length < 3) return null
    }
    return null
}

export const useAdminIBList = (options = {}) => {
    const {reference, name, fxAccount, includeClient, parent, ibCode, email, referralCode} = options;
    const swr = useSWRPaginationTemplate({
        key:
            includeClient !== undefined
                ? [
                      'admin/ib-list',
                      checkLength(reference),
                      checkLength(name),
                      checkLength(fxAccount),
                      includeClient,
                      parent,
                      ibCode,
                      checkLength(email),
                      checkLength(referralCode)
                  ]
                : null,
        request: async (
            [_, _reference, _name, _fxAccount, _includeClient, _parent, _ibCode, _email, _referralCode],
            _pageIndex,
            _pageSize,
        ) => {
            const query = {
                reference: _reference,
                username: _name,
                login: _fxAccount,
                includeClient: _includeClient,
                parent: _parent,
                ibCode: _ibCode,
                email: _email,
                referralCode: _referralCode,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(['ib', 'list'], {
                ...cleanObjectNilValue(query),
            });
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            reference: get(item, ':user.ref'),
                            ibLevel: get(item, ':user.ibLevel'),
                            name: get(item, ':user.name'),
                            regulation: get(item, ':user.regulation'),
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIbDetail = (options = {}) => {
    const {nodeId} = options;
    const swr = useSWRTemplate({
        key: nodeId ? ['admin/ib-detail', nodeId] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
        detail: get(swr, 'data.nodeInfo'),
        accounts: get(swr, 'data.fxAccounts'),
    };
};
export const useUserIbDetail = (options = {}) => {
    const {nodeId} = options;
    const swr = useSWRTemplate({
        key: nodeId ? ['client/ib-detail', nodeId] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            const childrenName = get(result, 'name').split(' ');
            return {
                ...result,
                childrenName: `${get(childrenName, '[0][0]') || ''}.${
                    get(childrenName, '[1][0]') || ''
                }`,
                commissionRate: get(result, 'commissionRate') || 0.2,
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
        detail: get(swr, 'data.nodeInfo'),
        accounts: get(swr, 'data.fxAccounts'),
    };
};

export const useAdminAllIBList = (options = {}) => {
    const {search, includeClient} = options;
    const swr = useSWRTemplate({
        key: ['admin/ib-all-list', search, includeClient],
        request: async ([_key, _search, _includeClient]) => {
            const query = {
                username: _search,
                includeClient: _includeClient,
            };
            const {result} = await api().get(
                ['ib', 'list'],
                cleanObjectNilValue(query),
            );
            return result.map((item) => {
                return {
                    ...item,
                    reference: get(item, ':user.ref'),
                    ibLevel: get(item, ':user.ibLevel'),
                    name: get(item, ':user.name'),
                    regulation: get(item, ':user.regulation'),
                    value: get(item, 'id'),
                    label: get(item, ':user.name'),
                };
            });
        },
        defaultValue: [],
    });
    return swr;
};

export const editIBParent = async (data) => {
    const {result} = await api().post(['ib', 'rebuild'], data);
    return result;
};

export const editIBDetail = async (data) => {
    const {result} = await api().post(['ib', 'info'], data);
};
export const editIBCodeDetail_ = async (data) => {
    const {result} = await api().post(['ib', 'code'], data);
};

export const editRewireTo = async (data) => {
    const {result} = await api().post(['fx', 'rewire-to'], data);
};

export const removeRewireTo = async (account) => {
    const {result} = await api().del(['fx', 'rewire-to', account]);
};

export const useIBCodeUpdateAffectCounter = (options = {}) => {
    const {sourceNodeId} = options;
    const swr = useSWRTemplate({
        key: sourceNodeId
            ? ['admin/ib-update-affect-number', sourceNodeId]
            : null,
        request: async ([_key, _sourceNodeId]) => {
            const {result} = await await api().get(['ib', 'code'], {
                sourceNodeId: _sourceNodeId,
            });
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const setSubLevelGroups = async (nodeId, data) => {
    const result = await api().post(['ib', 'info'], { sourceNodeId: Number(nodeId), ...data })
    return result
}

export const getSublevelTrades = async (query) => {
    const {result} = await api().get(['ib', 'trades'], cleanObjectNilValue(query))
    return result
}

export const downloadSublevelTrades = async (mt4=[], mt5=[], mt4s2=[], detail={}, startDate, endDate) => {
    const workbook = new Excel.Workbook();
    const columns = [
        {header: 'IB name', key: 'ibName'},
        {header: 'IB email', key: 'ibEmail'},
        {header: 'Trader account', key: 'traderAccount'},
        {header: 'Group', key: 'group'},
        {header: 'Leverage', key: 'leverage'},
        {header: 'Trader email', key: 'traderEmail'},
        {header: 'Trader status code', key: 'traderStatusCode'},
        {header: 'Rewire to account', key: 'rewireTo'},
        {header: 'Symbol', key: 'symbol'},
        {header: 'Type', key: 'cmd'},
        {header: 'Lots', key: 'lots'},
        {header: 'Profits', key: 'profits'},
        {header: 'Commissions', key: 'commissions'},
        {header: 'Agent of trading account', key: 'agentAccount'},
    ];
    let sheet,sheet2,sheet3

    if(mt4.length){
        sheet = workbook.addWorksheet('MT4s1');
        sheet.columns = columns
        for (const grant of mt4) {
            const row = [];
            for (const col of sheet.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd?'Sell':'Buy');
                        break;
                    case 'lots':
                        row.push(currencyFormaterOfficialWithSymbol(grant.volume));
                        break;
                    case 'profits':
                        row.push(currencyFormaterOfficialWithSymbol(grant.profit));
                        break;
                    case 'commissions':
                        row.push(currencyFormaterOfficialWithSymbol(grant.commission));
                        break;
                    case 'agentAccount':
                        row.push(grant.agentAccount);
                        break;
                }
            }
            sheet.addRow(row);
        }
        sheet.insertRow(1, { ibName: `From date: ${formatMonthDataAndYear(startDate||'')}`, ibEmail: `To date: ${formatMonthDataAndYear(endDate)}` });
        sheet.insertRow(2, { });
    }
    if(mt5.length){
        sheet2 = workbook.addWorksheet('MT5');
        sheet2.columns = columns
        for (const grant of mt5) {
            const row = [];
            for (const col of sheet2.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd?'Sell':'Buy');
                        break;
                    case 'lots':
                        row.push(currencyFormaterOfficialWithSymbol(grant.volume));
                        break;
                    case 'profits':
                        row.push(currencyFormaterOfficialWithSymbol(grant.profit));
                        break;
                    case 'commissions':
                        row.push(currencyFormaterOfficialWithSymbol(grant.commission));
                        break;
                }
            }
            sheet2.addRow(row);
        }
        sheet2.insertRow(1, { ibName: `From date: ${formatMonthDataAndYear(startDate||'')}`, ibEmail: `To date: ${formatMonthDataAndYear(endDate)}` });
        sheet2.insertRow(2, {  });
    }
    if(mt4s2.length){
        sheet3 = workbook.addWorksheet('MT4s2');
        sheet3.columns = columns
        for (const grant of mt4s2) {
            const row = [];
            for (const col of sheet3.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd?'Sell':'Buy');
                        break;
                    case 'lots':
                        row.push(currencyFormaterOfficialWithSymbol(grant.volume));
                        break;
                    case 'profits':
                        row.push(currencyFormaterOfficialWithSymbol(grant.profit));
                        break;
                    case 'commissions':
                        row.push(currencyFormaterOfficialWithSymbol(grant.commission));
                        break;
                }
            }
            sheet3.addRow(row);
        }
        sheet3.insertRow(1, { ibName: `From date: ${formatMonthDataAndYear(startDate||'')}`, ibEmail: `To date: ${formatMonthDataAndYear(endDate)}` });
        sheet3.insertRow(2, {  });
    }
    
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `${get(detail, 'name')}(${get(detail, 'ibCode')}) ${mt5.length?'MT5':'MT4'} sublevel trades.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
}