import React, {useEffect} from 'react';
import {
    Outlet,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Center,
    HStack,
    Image,
    LightMode,
    Stack,
    Text,
    useBreakpointValue,
    useColorMode,
} from '@chakra-ui/react';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import {useColorModeValueKey} from 'hooks/useColors';
import Page from 'components/Page';
import {usePlatformBasicInfo} from 'hooks/datahook/auth';
import {useInIframe} from 'hooks/useInIFrame';
import GuestBanner from './GuestBanner';
import OXLogoWhite from 'assets/images/OX_DP_Icon WHT.png';
import OXLanguageBtn from 'components/OXLangugeBtn';
import OXLoginBtn from 'components/OXLoginBtn';
import OXSignupBtn from 'components/OXSignupBtn';
import OXHomeBtn from 'components/OXHomeBtn';
import appConfig from 'config/app';
import OXLogo from 'assets/images/ox_dp_new.png';
import { useSourceRecorder } from 'hooks/useSourceRecorder';
import GoogleTagManager from 'components/GoogleTagManager';

const OXGuestLayout = (props) => {
    const {center = true} = props;
    const {t, i18n} = useTranslation('app');
    const {data: basicInfo, isLoading} = usePlatformBasicInfo();
    const inIframe = useInIframe();
    const {colorMode, setColorMode} = useColorMode();
    const [searchParams] = useSearchParams();
    const {pathname} = useLocation();
    const defaultColorMode = searchParams.get('colorMode');
    const defaultLang = searchParams.get('lang');
    const showHeader = searchParams.get('showHeader') === 'false';
    const isLoginPage = pathname.includes('auth/login');
    const isRegisterPage = pathname.includes('auth/sign-up');
    useSourceRecorder();
    useEffect(() => {
        if (colorMode !== 'light') {
            setColorMode('light');
        }
    }, [colorMode]);

    useEffect(() => {
        if (
            defaultLang &&
            i18n.language !== defaultLang &&
            ['en-US', 'zh-CN', 'zh-TW', 'jp-JP'].includes(defaultLang)
        ) {
            i18n.changeLanguage(defaultLang);
        }
    }, [defaultLang]);

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });
    return (
        <LightMode>
            {appConfig.isClient && 
            <GoogleTagManager />
            }
            <Page title={isLoading ? t('loading') : basicInfo.companyShortName}>
                {isMobile ? (
                    <Box bg={'#003566'} minH={'100vh'} pt={0}>
                        {showHeader ? null : (
                            <HStack
                                alignItems={'center'}
                                justify={'space-between'}
                                px={4}
                                py={4}
                            >
                                <Image src={OXLogo} height={'50px'} />
                                <HStack spacing={2}>
                                    <OXLanguageBtn />
                                    <OXLoginBtn />
                                </HStack>
                            </HStack>
                        )}

                        <Stack
                            spacing={0}
                            // bottom={0}
                            // left={0}
                            // right={0}
                            // h={'100%'}
                            minH={'calc(100vh - 80px)'}
                            bg={'white'}
                            borderTopLeftRadius={!showHeader?'30px': 0}
                            borderTopRightRadius={!showHeader?'30px': 0}
                            // px={6}
                            // py={4}
                            pt={4}
                        >
                            <Outlet />
                            {showHeader ? null : (
                                <Text
                                    lineHeight={'1.2'}
                                    fontSize="2xs"
                                    px={4}
                                    py={4}
                                >
                                    {t('oxGuestRiskWarning')}
                                </Text>
                            )}
                        </Stack>
                    </Box>
                ) : (
                    <HStack
                        alignItems={'stretch'}
                        h={'100vh'}
                        w={'100vw'}
                        bg={'white'}
                        spacing={0}
                    >
                        <GuestBanner />
                        <Stack
                            flex={1}
                            position={'relative'}
                            // bg={useColorModeValueKey('bg')}
                        >
                            {inIframe ? null : (
                                <HStack
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                    px={4}
                                    py={4}
                                    // position={'absolute'}
                                    // top={4}
                                    // left={4}
                                    // right={4}
                                    bg="white"
                                    zIndex={2}
                                >
                                    <Image src={OXLogo} height={'50px'} />
                                    <HStack spacing={2}>
                                        <OXLanguageBtn />
                                        <OXHomeBtn />
                                        {isLoginPage &&
                                        appConfig.appPermissionControl.includes(
                                            'USER',
                                        ) ? (
                                            <OXSignupBtn />
                                        ) : (
                                            <OXLoginBtn />
                                        )}
                                    </HStack>
                                </HStack>
                            )}
                            <Stack
                                mx="auto"
                                alignItems={'center'}
                                pt={isRegisterPage ? 0 : 24}
                                // justifyContent={
                                //     center ? 'center' : 'flex-start'
                                // } // center will cause scroll can not scroll to top
                                // position={'absolute'}
                                width={'100%'}
                                // minHeight={'100vh'}
                                h={'100%'}
                                overflow={'auto'}
                                // bg={useColorModeValueKey('bg')}
                            >
                                <Box my={3}>
                                    <Outlet />
                                </Box>
                            </Stack>
                            <Box px={6} py={4}>
                                <Text lineHeight={'1.2'} fontSize="2xs">
                                    {t('oxGuestRiskWarning')}
                                </Text>
                            </Box>
                        </Stack>
                    </HStack>
                )}
            </Page>
        </LightMode>
    );
};

export default OXGuestLayout;
