import {processUserProfile} from 'data/userProfile';
import client, {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {isNil, split} from 'lodash';
import {get, trim} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {getAddressPayload} from 'data/common';
import {formatMonthDataAndYear} from 'utils/formator';
import {getLegalRepresentativePayloadObj} from 'data/leaglRepresentative';
import {isValid} from 'date-fns';
import {processForexAccountList} from '../../data/forex';
import {useAuth} from '../useAuth';
import {processReferee} from '../../data/referee';
import {processMessage} from '../../data/message';
import { processCCAccount, processCCBankInfo } from 'data/ccAccount';

export const useAdminClients = (options = {}) => {
    let {
        search,
        kycStatus,
        type,
        region,
        regulation,
        riskLevel,
        ibCode,
        saleCode,
        reference,
        account,
        ibType,
        startDate,
        endDate,
        heardFrom
    } = options;
    search = search.length > 2 ? search : null;
    const swr = useSWRPaginationTemplate({
        key: [
            'admin/clients',
            search,
            kycStatus,
            type,
            region,
            ibCode,
            saleCode,
            reference,
            account,
            ibType,
            startDate,
            endDate,
            heardFrom
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _search,
                _kycStatus,
                _type,
                _region,
                _ibCode,
                _saleCode,
                _reference,
                _account,
                _ibType,
                _startDate,
                _endDate,
                _heardFrom
            ] = _key;
            const {result} = await api().get(
                ['user'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    keyword: _search,
                    status: _kycStatus,
                    type: _type,
                    region: _region,
                    ibCode: _ibCode,
                    saleCode: _saleCode,
                    reference: _reference,
                    account: _account,
                    ibType: _ibType,
                    startDate: _startDate? formatMonthDataAndYear(_startDate): null,
                    endDate: _endDate? formatMonthDataAndYear(_endDate): null,
                    heardFrom: _heardFrom
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) =>
                        processUserProfile(item),
                    ),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
    return swr;
};

export const useAdminClientSearch = (options = {}) => {
    const {request = true, search = null, isIB, kycStatus = null} = options;
    const swr = useSWRTemplate({
        key: request && ['admin/clientsSearch', search, isIB, kycStatus],
        request: async ([_, _search, _isIB, _kycStatus]) => {
            const {result} = await api().get(
                ['commons', 'user-list'],
                cleanObjectNilValue({
                    username: _search,
                    isIb: _isIB,
                    kycStatus: _kycStatus,
                }),
            );

            return result.map(processUserProfile);
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: swr?.data.map((item) => ({
            ...item,
            label: `${get(item, 'name')} (${get(item, 'ref')})`,
            value: item?.id,
        })),
    };
};

export const assignSaleToClient_ = async ({sale, user}) => {
    const {result} = await adminClient.post(['assign-sale'], {
        sale,
        user,
    });
    return result;
};

export const draftKyc_ = async ({id, reason}) => {
    const {result} = await adminClient.post(['kyc', 'draft'], {userId: id});
    return result;
};

export const renewKyc_ = async ({id, reason}) => {
    const {result} = await adminClient.post(['kyc', 'renew'], {userId: id});
    return result;
};
export const suspendKyc_ = async ({id}) => {
    const {result} = await adminClient.post(['kyc', 'suspend'], {userId: id});
    return result;
};

export const unsuspendKyc_ = async ({id}) => {
    const {result} = await adminClient.post(['kyc', 'unsuspend'], {userId: id});
    return result;
};

export const disableClientAccount_ = async ({id}) => {
    const {result} = await adminClient.post(['kyc', 'disable'], {userId: id});
    return result;
};

export const activeClientAccount_ = async ({id}) => {
    const {result} = await adminClient.post(['kyc', 'enable'], {userId: id});
    return result;
};
export const unDeclineKyc_ = async ({id}) => {
    const {result} = await adminClient.post(['kyc', 'unDecline'], {userId: id});
    return result;
};

export const getKycTaskDetail_ = async ({user}) => {
    const result = getTaskDetail_({user, type: 'kyc'});
    return result;
};
export const getBeneficiaryTaskDetail_ = async ({user}) => {
    const result = await getTaskDetail_({user, type: 'beneficiary'});
    return result;
};

const getTaskDetail_ = async ({user, type}) => {
    const {result} = await adminClient.get(['task-detail'], {
        user,
        type,
    });
    return result;
};

export const approveKyc_ = async ({id, approvalId}) => {
    const {result} = await adminClient.post(['kyc', 'approval'], {
        userId: id,
        approvalId,
    });
    return result;
};

export const declinedKyc_ = async ({id, reason, approvalId}) => {
    const {result} = await adminClient.post(['kyc', 'decline'], {
        userId: id,
        reason: reason,
        approvalId,
    });
    return result;
};
export const returnKyc_ = async ({id, reason, approvalId}) => {
    const {result} = await adminClient.post(['kyc', 'return'], {
        userId: id,
        reason: reason,
        approvalId,
    });
    return result;
};
export const directApprove_ = async ({id, approvalId}) => {
    const {result} = await adminClient.post(['kyc', 'approval'], {
        userId: id,
        approvalId,
    });
    return result;
};

export const useAdminClientDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['admin/client-detail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['user', _id]);
            return isNil(result) ? null : processUserProfile(result);
        },
        defaultValue: {},
    });
    return {
        ...swr,
        renewKyc_: async () => {
            const result = await renewKyc_(id);
            await swr.mutate();
            return result;
        },
        suspendKyc_: async () => {
            const result = await suspendKyc_(id);
            await swr.mutate();
            return result;
        },
        updateKyc_: async ({
                               person,
                               //    estimate,
                               personDocuments,
                               note,
                               company,
                               legalRepresentatives,
                               sameLegalReprensentativeIndex,
                               authorisedPerson,
                           }) => {
            const payload = {
                note,
            };
            // const transferNeeds = get(estimate, 'transferNeeds', '');
            // const fromCurrency = split(transferNeeds, '/')?.[0] || '';
            // const toCurrency = split(transferNeeds, '/')?.[1] || '';
            if (get(swr, 'data.isCompany')) {
                const representatives = legalRepresentatives
                    .map(getLegalRepresentativePayloadObj)
                    .map((item) => ({...item, isLR: true, isAP: false}));
                sameLegalReprensentativeIndex == null;
                payload.companyInfo = {
                    company: {
                        name: get(company, 'name', ''),
                        businessNo: get(company, 'businessNo', ''),
                        industryName: get(company, 'industryName', ''),
                        officialWebsite: get(company, 'businessURL', ''),
                        region: get(company, 'region'),
                    },
                    // estimate: {
                    //     fromCurrency,
                    //     toCurrency,
                    //     amount: Number(get(estimate, 'transferAmount', '')),
                    // },
                    address: getAddressPayload(company, 'company'),
                    documents: get(company, 'companyDocuments', []),
                    representatives: isNil(sameLegalReprensentativeIndex)
                        ? [
                            ...representatives,
                            {
                                ...getLegalRepresentativePayloadObj(
                                    authorisedPerson,
                                ),
                                isLR: false,
                                isAP: true,
                            },
                        ]
                        : [
                            ...representatives.map((item, index) => ({
                                ...item,
                                isAP: index === sameLegalReprensentativeIndex,
                            })),
                        ],
                };
            } else {
                //individual
                const phone = get(person, 'phone', '');
                const phoneCode = split(phone, '@')?.[0] || '';
                const phoneNo = split(phone, '@')?.[1] || '';

                const address = getAddressPayload(person, 'individual');
                payload.individualInfo = {
                    person: {
                        title: get(person, 'title', ''),
                        firstname: get(person, 'firstname', ''),
                        middlename: get(person, 'middlename', ''),
                        lastname: get(person, 'lastname', ''),
                        nativeLanguageFirstname: get(
                            person,
                            'firstNameNative',
                            '',
                        ),
                        nativeLanguageMiddlename: get(
                            person,
                            'middleNameNative',
                            '',
                        ),
                        nativeLanguageLastname: get(
                            person,
                            'lastNameNative',
                            '',
                        ),
                        phoneCode,
                        phoneNo,
                        region: get(address, 'country', ''),
                        occupation: get(person, 'occupation', ''),
                        dob: get(person, 'dob', '')
                            ? formatMonthDataAndYear(get(person, 'dob', ''))
                            : null,
                        frontPhotoOfPrimary: get(
                            personDocuments,
                            'primaryDocumentFrontSide',
                        ),
                        backPhotoOfPrimary: get(
                            personDocuments,
                            'primaryDocumentBackSide',
                        ),
                        photoOfSecondary: get(
                            personDocuments,
                            'secondaryIdentificationDocument',
                        ),
                        photoOfAdditional:
                            get(
                                personDocuments,
                                'additionalPhotoIdentification',
                            ) || null,
                    },
                    // estimate: {
                    //     fromCurrency,
                    //     toCurrency,
                    //     amount: Number(get(estimate, 'transferAmount', '')),
                    // },
                    address: address,
                };
            }
            await adminClient.put(
                ['user-kyc', id],
                cleanObjectNilValue(payload),
            );
            await swr.mutate();
        },
    };
};

export const useAdminClientNotes = (options = {}) => {
    const {id} = options;
    const swr = useSWRPaginationTemplate({
        key: ['admin/user-notes', id],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id] = _key;
            const {result} = await api().get(
                ['log', 'note'],
                cleanObjectNilValue({
                    user: _id,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => item),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });

    return {
        ...swr,
        createNotes_: async (content) => {
            await adminClient.post(['log', 'note'], {
                user: id,
                content,
            });
            await swr.mutate();
        },
        updateNotes_: async ({message, id}) => {
            await adminClient.put(['user-note', id], {
                message,
            });
            await swr.mutate();
        },
    };
};

export const useAdminLeadNotes = (options = {}) => {
    const {id, request = true} = options;
    const swr = useSWRTemplate({
        key: id && request ? ['admin-lead-notes', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(
                ['log', 'note'],
                cleanObjectNilValue({
                    prospect: _id,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        createNotes_: async (content) => {
            await adminClient.post(['log', 'note'], {
                prospect: id,
                content,
            });
            await swr.mutate();
        },
    };
};

export const useAdminProspect = (options = {}) => {
    const {email, mobile, type, withRecord} = options;
    const swr = useSWRPaginationTemplate({
        key: ['admin/prospect', email, mobile, type, withRecord],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _email, _mobile, _type, _withRecord] = _key;
            const {result} = await api().get(
                ['prospect'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    email: _email,
                    mobile: _mobile,
                    type: _type,
                    withRecord: _withRecord,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items'),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
    return {
        ...swr,
        deleteProspect_: async (id) => {
            await deleteProspect_(id);
            await swr.mutate();
        },
        asslignSaleToProspect_: async ({prospect, sale}) => {
            await asslignSaleToProspect_({prospect, sale});
            await swr.mutate();
        },
    };
};

export const asslignSaleToProspect_ = async ({prospect, sale}) => {
    const {result} = await adminClient.post(['prospect', 'assign-sale'], {
        sale,
        prospect,
    });
    return result;
};

export const deleteProspect_ = async (id) => {
    await adminClient.del(['prospect', id]);
};

export const useSaleAssignedClients = (options = {}) => {
    const {email, name, status, sale} = options;
    return useSWRPaginationTemplate({
        key: sale
            ? ['sale/sale-assigned-clients', sale, email, name, status]
            : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _sale, _email, _name, _status] = _key;
            const {result} = await adminClient.get(
                ['sale', 'user'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    email: _email,
                    name: _name,
                    status: _status,
                    sale: _sale,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processUserProfile),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
};

export const useSaleAssignedProspect = (options = {}) => {
    const {email, sale} = options;
    const swr = useSWRPaginationTemplate({
        key: sale ? ['sale/sale-assigned-prospect', sale, email] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _sale, _email] = _key;
            const {result} = await adminClient.get(
                ['sale', 'prospect'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    email: _email,
                    sale: _sale,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items'),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
    return {
        ...swr,
        deleteProspect_: async (id) => {
            await deleteProspect_(id);
            await swr.mutate();
        },
    };
};

export const useClientFeeSetting = (options = {}) => {
    const {user} = options;
    const swr = useSWRTemplate({
        key: user ? ['admin/client-fee-setting', user] : null,
        request: async ([_, _user]) => {
            const {result} = await api().get(['fee-setting'], {user: _user});
            return {
                id: get(result, 'id'),
                conversionFee: get(result, 'conversionFee'),
                transferFee: get(result, 'transferFee'),
                paymentFee: get(result, 'paymentFee'),
            };
        },
        defaultValue: [],
    });
    return {
        ...swr,
        updateFeeSetting_: async (
            id,
            {conversionFee, transferFee, paymentFee},
        ) => {
            console.log(conversionFee, transferFee, paymentFee);
            const {result} = await api().put(['fee-setting', id], {
                conversionFee,
                transferFee,
                paymentFee,
            });
            await swr.mutate();
        },
    };
};

export const updateClientPassword_ = async ({user, password}) => {
    return await adminClient.post(['reset-password'], {user, password});
};

export const updateClientKycExpireDate_ = async ({user, date}) => {
    const formatedDate =
        date && isValid(date) ? formatMonthDataAndYear(date) : null;
    return await adminClient.post(
        ['user-kyc-expire'],
        cleanObjectNilValue({
            user,
            date: formatedDate,
        }),
    );
};

export const adminAllowDevelop_ = async ({enable, user}) => {
    return await adminClient.post(['allow-develop'], {enable, user});
};

export const adminEditIBLevel_ = async ({levelCode, user}) => {
    return await adminClient.post(['ib-level'], {levelCode, user});
};

export const useAssignees = (options = {}) => {
    const {search, request = true} = options;
    const swr = useSWRTemplate({
        key: request
            ? search
                ? ['admin/assignees', search]
                : ['admin/assignees']
            : null,
        request: async ([_, _search]) => {
            // const {result} = await api().get(['fee-setting'], {user: _user});
            const {result} = await adminClient.get(
                ['staff'],
                cleanObjectNilValue({
                    username: _search,
                }),
            );
            return result.map((item) => ({
                id: item.id,
                name: item.name,
                avatar: get(item, ':preference.avatar'),
                value: item.id,
            }));
        },
        defaultValue: [],
    });
    return swr;
};

export const useLeadList = (options = {}) => {
    const {
        search = null,
        regulation = null,
        status,
        startDate,
        endDate,
        sale,
        withRecord,
        heardFrom
    } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'lead-list',
            search,
            regulation,
            status,
            startDate,
            endDate,
            sale,
            withRecord,
            heardFrom
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _search,
                _regulation,
                _status,
                _startDate,
                _endDate,
                _sale,
                _withRecord,
                _heardFrom
            ] = _key;
            const {result} = await api().get(
                ['prospect'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    email: _search ? trim(_search) : null,
                    regulationId: _regulation ? trim(_regulation) : null,
                    status: _status ? _status : null,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    salePerson: _sale ? Number(_sale) : null,
                    withRecord: _withRecord,
                    heardFrom: _heardFrom
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processReferee),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });

    return {
        ...swr,
    };
};

export const useNoSaleLeadList = (options = {}) => {
    const {sale} = options
    const swr = useSWRPaginationTemplate({
        key: ['no-sale-lead-list', sale],
        request: async (_key, _pageIndex, _pageSize) => {
            const [ _, _sale ] = _key;
            const {result} = await api().get(
                ['no-sale-prospect'],
                cleanObjectNilValue({
                    sale: _sale,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processReferee),
                },
            };
        },
        defaultValue: {
            totalItems: 0,
            items: []
        },
        // pageSize:30,
    });

    return {
        ...swr,
    };
};

export const batchAssignSale = async (data) => {
    const {result} = await api().post(['prospect', 'batch-assign'], cleanObjectNilValue(data))
    return result
}

export const useNoSaleClientList = (options = {}) => {
    const { sale, preAssignSale } = options
    const swr = useSWRPaginationTemplate({
        key: ['no-sale-client-list', sale, preAssignSale],
        request: async (_key, _pageIndex, _pageSize) => {
            const [ _, _sale, _preAssignSale ] = _key;
            const {result} = await api().get(
                ['user', 'batch-assign-sale'],
                cleanObjectNilValue({
                    sale: _sale === 'false'? false:_sale,
                    preAssignSale: _preAssignSale === 'true'? true:_preAssignSale,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processUserProfile),
                },
            };
        },
        defaultValue: {
            totalItems: 0,
            items: []
        },
        // pageSize:30,
    });

    return {
        ...swr,
    };
};

export const batchAssignSaleForClient = async (data) => {
    const {result} = await api().post(['user', 'batch-assign-sale'], cleanObjectNilValue(data))
    return result
}

export const useSalePerson = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['salePerson'],
        request: async ([_, _type]) => {
            const {result} = await api().get(['staff', 'sale-list']);
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useFxAccounts = (options = {}) => {
    const {
        login,
        client,
        source,
        status,
        user,
        accountId,
        accountType,
        enable,
        flag,
        group,
        onlyRewired=false
    } = options;

    const swr = useSWRPaginationTemplate({
        key: [
            'fx-account',
            login,
            client,
            source,
            status,
            user,
            accountId,
            accountType,
            enable,
            flag,
            group,
            onlyRewired
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _login,
                _client,
                _source,
                _status,
                _user,
                _accountId,
                _accountType,
                _enable,
                _flag,
                _group,
                _onlyRewired
            ] = _key;

            const query = {
                login: _login,
                username: _client,
                source: _source,
                status: _status,
                user: _user,
                accountId: _accountId,
                type: _accountType ? _accountType : 'Live,Rebate',
                enable: _enable,
                onlyRewired: _onlyRewired,
                flag:
                    _flag === null || _flag === undefined
                        ? null
                        : _flag === '1'
                        ? 'true'
                        : 'false',
                group: _group,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['fx', 'account'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items')
                        .map(processForexAccountList)
                        .sort((a, b) => b.balance - a.balance),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};
export const useRiskAccounts = (options = {}) => {
    const {
        login,
        client,
        source,
        status,
        user,
        accountId,
        accountType,
        enable,
        flag,
        group,
        balance
    } = options;

    const swr = useSWRPaginationTemplate({
        key: [
            'fx-account',
            login,
            client,
            source,
            status,
            user,
            accountId,
            accountType,
            enable,
            flag,
            group,
            balance
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _login,
                _client,
                _source,
                _status,
                _user,
                _accountId,
                _accountType,
                _enable,
                _flag,
                _group,
                _balance
            ] = _key;

            const query = {
                login: _login,
                username: _client,
                source: _source,
                status: _status,
                user: _user,
                accountId: _accountId,
                enable: _enable,
                balance: _balance? _balance: 50000,
                flag:
                    _flag === null || _flag === undefined
                        ? null
                        : _flag === '1'
                        ? 'true'
                        : 'false',
                group: _group,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['risk', 'account', 'high-leverages'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items').map(item=>{
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, 'userId'),
                                name: get(item, 'username')
                            }
                        }                        
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useClientReferees = (options = {}) => {
    const {id, status, name} = options;
    const swr = useSWRPaginationTemplate({
        key: id ? ['ClientReferees', id, status, name] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id, _status, _name] = _key;
            const {result} = await adminClient.get(
                ['invite', 'user'],
                cleanObjectNilValue({
                    userId: _id,
                    status: _status,
                    username: _name,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processReferee),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const suspendAccounts = async (accountId) => {
    const {result} = await adminClient.post(['fx', 'account', accountId], {
        status: 'suspended',
    });
    return result;
};

export const deleteLead_ = async (id) => {
    const {result} = await adminClient.del(['prospect', id]);
    return result;
};

export const setRegulation_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['prospect', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const setSalePerson_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['prospect', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const exportLeadsTemplate_ = async (options = {}) => {
    return await api().download(
        ['import', 'template'],
        {
            type: 'prospect',
        },
        {responseType: 'blob'},
    );
};

export const onUploadLeadsFile_ = async (file) => {
    const {result} = await api().upload(['import', 'prospect'], file);
    return result;
};

export const useSelectClients = () => {
    const swr = useSWRTemplate({
        key: ['admin/select-clients'],
        request: async (_key) => {
            const {result} = await api().get(['commons', 'user-list']);
            return result;
        },
        defaultValue: [],
        // pageSize:30,
    });
    return swr;
};

export const useSelectLevels = () => {
    const swr = useSWRTemplate({
        key: ['admin/select-levels'],
        request: async (_key) => {
            const {result} = await api().get(['commons', 'ib-level']);
            return result;
        },
        defaultValue: [],
        // pageSize:30,
    });
    return swr;
};

export const useSelectIBs = () => {
    const swr = useSWRTemplate({
        key: ['admin/select-ibs'],
        request: async (_key) => {
            const {result} = await api().get(['commons', 'ib-list']);
            return result.map((item) => {
                let type = get(item, 'type') === 'ib' ? 'IB' : 'Client';
                return {
                    label: `${get(item, ':user.name')}(${get(
                        item,
                        ':user.ref',
                    )})`,
                    value: get(item, 'id'),
                };
            });
        },
        defaultValue: [],
        // pageSize:30,
    });
    return swr;
};
export const useSelectUpperIB = (options) => {
    const {search, upperName, includeClient} = options;
    const swr = useSWRTemplate({
        key: ['admin/select-ibs', search, includeClient, upperName],
        request: async (_key) => {
            const [_, _search, _includeClient, _upperName] = _key;
            const query = {
                username: _search === '' ? _upperName : _search,
                includeClient: _includeClient,
            };
            const {result} = await api().get(
                ['commons', 'ib-list'],
                cleanObjectNilValue(query),
            );
            return result.map((item) => {
                return {
                    ...item,
                    reference: get(item, ':user.ref'),
                    ibLevel: get(item, ':user.ibLevel'),
                    name: get(item, ':user.name'),
                    regulation: get(item, ':user.regulation'),
                    value: get(item, ':user.id'),
                    label: get(item, ':user.name'),
                };
            });
        },
        defaultValue: [],
        // pageSize:30,
    });
    return swr;
};

export const useSaleList = () => {
    const swr = useSWRTemplate({
        key: ['admin/sale-list'],
        request: async () => {
            const {result} = await api().get(['staff', 'sale-list']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const useSupportList = () => {
    const swr = useSWRTemplate({
        key: ['admin/support-list'],
        request: async () => {
            const {result} = await api().get(['commons', 'support-list']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const useManagerSales = (options = {}) => {
    const {search} = options;
    const swr = useSWRTemplate({
        key: ['managerSales', search],
        request: async (_key) => {
            const [_, _search] = _key;
            const query = {
                username: _search === '' ? null : _search,
            };
            const {result} = await adminClient.get(['staff', 'sale-list'], cleanObjectNilValue(query));
            return result;
        },
        defaultValue: [],
    });
    return swr;
};
export const useManagerSupports = (options = {}) => {
    const {search} = options;
    const swr = useSWRTemplate({
        key: ['managerSupports', search],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _search] = _key;
            const query = {
                username: _search === '' ? null : _search,
            };
            const {result} = await adminClient.get(['commons', 'support-list'], cleanObjectNilValue(query));
            return result;
            return {
                result: {
                    totalItems: get(result, 'totalItems', 0),
                    items: get(result, 'items', []),
                },
                // result: {
                //     totalItems: 0,
                //     items: [],
                // },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useClientInteractions = (options = {}) => {
    const {user} = options;
    const swr = useSWRPaginationTemplate({
        key: user ? ['ClientInteractions', user] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _user] = _key;
            const {result} = await adminClient.get(
                ['log', 'interaction'],
                cleanObjectNilValue({
                    userId: _user,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processMessage),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminUserList = (options = {}) => {
    const {search = ''} = options;
    const swr = useSWRTemplate({
        key: ['admin/userList', search],
        request: async ([_, _search]) => {
            const {result} = await api().get(
                ['commons', 'user-list'],
                cleanObjectNilValue({
                    username: _search,
                }),
            );

            return result.map(processUserProfile);
        },
        defaultValue: [],
    });
    return {
        ...swr,
        data: swr?.data.map((item) => ({
            ...item,
            label: `${get(item, 'name')} (${get(item, 'email')})`,
            value: item?.id,
        })),
    };
};

export const assignClientSale = async (data) => {
    const {result} = await api().post(['user', 'assign-sale'], data);
    return result;
};

export const editRiskLevel = async (data) => {
    const {result} = await api().post(['user', 'risk-level'], data);
    return result;
};

export const editRegulation = async (data) => {
    const {result} = await api().post(['user', 'regulation'], data);
    return result;
};

export const editClientRegion_ = async (data) => {
    const {result} = await api().post(['user', 'region'], data);
    return result;
};

export const updateClientPrefernece_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['user-setting', id],
        cleanObjectNilValue(data),
    );
    return result;
};
export const updateClientAvatar_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['user','client-avatar', id],
        cleanObjectNilValue(data),
    );
    return result;
};


export const updateWithdrawalAccountStatus_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['withdrawal-account', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const updateClientInformation_ = async (data) => {
    const {result} = await api().post(
        ['user-contact'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const sendMessageToClient_ = async (data) => {
    const {result} = await api().post(
        ['user-send-message'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const supportChangeAccountUser = async (data) => {
    const {result} = await api().post(['support', 'change-account-user'], cleanObjectNilValue(data));
    return result;
};



export const useAdminClientAdditionalFiles = (options = {}) => {
    const {id} = options;
    const swr = useSWRPaginationTemplate({
        key: ['admin/user-additional-files', id],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id] = _key;
            const {result} = await api().get(
                ['user', 'user-files',_id],
                cleanObjectNilValue({
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => item),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });

    return {
        ...swr,
        createFilesRecord_: async ({files, description}) => {
            await adminClient.put(['user', 'user-files', id], {
                files: files,
                description,
            });
            await swr.mutate();
        },
    };
};

export const useCCSubAccounts = (options = {}) => {
    const {
        client,
        user,
        // currency,
    } = options;

    const swr = useSWRPaginationTemplate({
        key: ['cc-sub-account', client, user],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _client,
                _user,
                // _currency,
            ] = _key;

            const query = {
                username: _client,
                user: _user,
                // currency: _currency,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['currency-account'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items').map(processCCAccount),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useCCSubAccountDetail = (options = {}) => {
    const {
        id
    } = options;

    const swr = useSWRTemplate({
        key: id && ['cc-sub-account',id],
        request: async ([_,_id]) => {
            const {result} = await adminClient.get(
                ['currency-account',_id]
            );
            return processCCAccount(result);
        },
        defaultValue: {}
    });
    return swr;
};

export const useMarketingReport = (options={}) => {
    const {username, country, ref, sort, kycStatus} = options
    return useSWRPaginationTemplate({
        key: ['useMarketingReport', username, country, ref, sort, kycStatus],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _username, _country, _ref, _sort, _kycStatus] = _key
            const sortColumn =  _sort?.split('/')[0]
            const sortType = _sort?.split('/')[1]
            const query = {
                username: _username?_username:null,
                country: _country,
                ref: _ref?_ref:null,
                orderBy: sortColumn,
                sort: sortType,
                kycStatus: _kycStatus,
                pageIndex: _pageIndex-1,
                pageSize: _pageSize
            }
            const {result} = await api().get(['user', 'marketing-report'], cleanObjectNilValue(query))
            return {
                result: {
                    items: get(result, 'items').map(item=>{
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, 'userId'),
                                name: get(item, 'username')
                            }
                        }
                    }),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: {
            items: [],
            totalItems: 0
        }
    })
}

export const useMarketingReportDownloadList = (options={}) => {
    const {username, country, ref} = options
    return useSWRPaginationTemplate({
        key: ['useMarketingReportDownloadList', username, country, ref],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _username, _country, _ref] = _key
            const query = {
                username: _username?_username:null,
                country: _country,
                ref: _ref?_ref:null,
                pageIndex: _pageIndex-1,
                pageSize: _pageSize
            }
            const {result} = await api().get(['user', 'marketing-report','download'], cleanObjectNilValue(query))
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems')
                }
            }
        },
        defaultValue: {
            items: [],
            totalItems: 0
        }
    })
}

export const getClientActivitiesFile = async () => {
    return await api().download(
		["export-excel"],
		{ date: date },
		{ responseType: "blob" }
	);
}

export const downloadFile = async (
	url,
	name = "template.xlsx",
	type = "application/vnd.openxmlformats"
) => {
	let a = window.document.createElement("a");
	// a.href = URL.createObjectURL(new Blob([blobFile], { type }));
	a.href = url
	a.download = name;
	window.document.body.appendChild(a);
	a.click();
	window.document.body.removeChild(a);
};